<template>
<div style="margin-top: 100px">
    
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-5 col-lg-6">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchClients">Buscar</button>
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>
            <p class="py-3  pb-3 text-center d-flex pt-3 pl-3 bg-primary text-white">Clique no + para adicionar nas permissões</p>
           <div class="flex-wrap d-flex py-3 pb-3 pl-3 pr-3 bg-white justify-content-start w-100"> 
                <div class="col-lg-4"  v-for="permissao in permissoes" :key="permissao.id" >
                    <p><b>{{permissao.nome}} <i class="far fa-trash-alt" style="color:red" @click="removerPermissao(permissao.id)"></i></b></p>
                </div>
                <button class="btn btn-primary" @click="salvarPermissoes">Salvar</button>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Nome</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">E-mail</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Whatsapp</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    

                    <div class="card-list" v-for="item in clients" :key="item.id" > 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row" >
                                        <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                            <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <img v-if="item.image" :src="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                        </div>
                                        <div class="col-7 col-md-6 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{ item.first_name }} {{ item.last_name }}</h6>
                                                        <h6 class="text-truncate cpf">{{ item.cpf }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate e-mail">{{ item.email }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="text-truncate whats">{{ item.cellphone }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div  class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <p @click="adicionarPermissoes(item.first_name+ ' '+item.last_name, item.id)" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-center p-4" v-if="clients.length == 0">
                        Nenhum cliente encontrado.
                    </div>

                   
                    </div>


                    
                </div>
                <div class="col-12">
                    <BasePagination
                        v-if="clients.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
            </div>
        </div>
</div>
</template>
<script>
import ClientService from "@/services/resources/ClientService";
import ClientCanService from "@/services/resources/ClientCanService";

import SyncUsersService from "@/services/resources/Uau/SyncUsers";
import ImportNotImportedClientService from "@/services/resources/Uau/ImportNotImportedClient"

const service = ClientService.build();
const serviceCan = ClientCanService.build();
const serviceSyncUsersUau = SyncUsersService.build();
let serviceImportNotImportedClient = ImportNotImportedClientService.build()

export default {
    data(){
        return{
            disabledButtonSyncronized: false,
            clients: [],
            total_clients: 0,
            total_uau: 0,
            permissoes: [],
            uau_clients_not_imported: [],
            integrations: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'clientes',
                    to: 'Client'
                }
            ],
            menu:[
                {
                    name: 'Novo cliente',
                    to: 'ClientNew'
                },
                {
                    name: 'Clientes',
                    to: 'Client'
                }
            ]
        }
    },
    methods:{
        sincronizedUAU() {

            this.disabledButtonSyncronized = true;
                this.$bvToast.toast('Processo de importação de dados em andamento, por favor aguarde.', {
                    title: 'Atenção',
                    autoHideDelay: 10000,
                    type: 'success'
                    });

            serviceSyncUsersUau.search('').then(() => {
                this.disabledButtonSyncronized = false;
            })
        },  
        adicionarPermissoes(nome, id) {
            var existe = this.permissoes.filter(function(re){
                if (re.id == id) {
                    return re
                }
            })
            if (existe.length < 1) {
                this.permissoes[this.permissoes.length] = {
                    nome: nome,
                    id: id
                }
            }
            this.$forceUpdate()
        },
        removerPermissao(id) {
              var permissoes = this.permissoes.filter(function(re){
                if (re.id !== id) {
                    return re
                }
            })
            this.permissoes = permissoes
        },
         toPage(page) {
            this.pagination.currentPage = page;
            this.fetchClients();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchClients();
        },
        salvarPermissoes(){
            var informacao = {
                can: this.permissoes
            }
            serviceCan.create(informacao).then(function(re) {
                      this.$bvToast.toast('Permissões adicionadas!', {
                        title: 'Permissões adicionadas',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
            })
        },
        fetchClients(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['search'] = this.search;
            }

             service
                .search(data)
                .then(resp => {
                    this.total_clients = resp[0].total;
                    this.total_uau = resp.total_uau;
                    this.clients = resp[0].data.map(item => {
                        return {
                            ...item,
                            cellphone: item.cellphone ? item.cellphone : 'NÃO CADASTRADO ',
                            cpf: item.cpf ? item.cpf : item.cnpj,
                            first_name: item.first_name ? item.first_name : item.social_name,
                        }
                    });
                    this.pagination.totalPages = resp[0].last_page;
                    this.pagination.itens = resp[0].total;
                })
                .catch(err => {
                console.log(err)
                })
        },
    },
    mounted(){
        this.fetchClients();
        this.integrations =  this.$store.getters.getProfile.company.integrations
        console.log(this.$store.getters.getProfile);
    }
}
</script>