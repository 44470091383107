import Rest from '@/services/Rest';

/**
 * @typedef {ImportNotImportedClient}
 */
export default class ImportNotImportedClient extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/uau-clients-not-imported'
}
