import Rest from '@/services/Rest';

/**
 * @typedef {SyncUsers}
 */
export default class SyncUsers extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/syncronize-users'
}
